<template>
  <div class="main">
    <div class="title">{{doc.title}}</div>
    <div class="content" v-html="doc.content"></div>
  </div>
</template>

<script>

import {
  ref,
  onMounted
} from 'vue';
import { useRoute } from 'vue-router';
import Baike from "../../models/Baike";

export default {
  setup() {
    const route = useRoute();
    let id = route.query.id;
    let doc = ref({});
    onMounted(() => {
      let param = {
        'id': id
      }
      Baike.docDetail(param).then(res => {
        doc.value = res.data.detail
        document.title = res.data.detail.title
      });
    });

    return {
      doc
    };
  },

};
</script>
<style scoped>
.main {
  margin: 30px 0 100px 0;
  letter-spacing: 1px;
}
.title {
  padding: 10px 15px 15px 0;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  background-color: #fff;
}
.content {
  text-align: left;
  padding: 0 15px;
  font-size: 18px;
  background-color: #fff;
  overflow: hidden; /*解决内部的p元素margin溢出*/
}
.content >>> .header__title {
  display: none;
}
.content >>> .content__list__title {
  font-weight: 700;
}
</style>